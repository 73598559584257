<template>
  <List
    :config="blockData.config"
    :initial-data="blockData.initialData"
    :initial-filters="blockData.initialFilters"
    state-in-url
    has-filter-panel
    @toggle-filter-panel="filterPanelOpen = !filterPanelOpen"
  >
    <template #entries="{ entries, filters }">
      <TileView
        ref="tilesContainer"
        :tiles="entries"
        :grid-type="ETileGridType.SINGLE"
        :query-args="getQueryArgsForTiles(filters)"
      />
    </template>
    <template #no-entries>
      <NoEntries :type-handle="blockData.typeHandle" />
    </template>
    <template #loader>
      <LoaderTileRow></LoaderTileRow>
      <LoaderTileRow class="mt-md"></LoaderTileRow>
    </template>
    <template #filters="{ filters, filterOptions, changeFilter, resetFilters }">
      <FilterPanel
        :open="filterPanelOpen"
        @close="filterPanelOpen = false"
        @reset-filters="resetFilters"
      >
        <div
          :class="[
            'flex flex-col gap-sm pb-sm md:bg-vdv-grey mt-sm',
            'touch:md:grid-cols-12',
            'desktop:md:grid-cols-8 md:grid md:gap-md md:!p-sm md:mt-md',
            'desktop:xl:grid-cols-12',
          ]"
        >
          <div class="col-span-2">
            <DropdownFilter
              label="office.location"
              :filter-key="FilterType.LOCATION"
              :filters="filters"
              :filter-options="filterOptions"
              @change-filter="changeFilter"
            />
          </div>
          <div class="col-span-2">
            <DropdownFilter
              label="office.branchType"
              :filter-key="FilterType.BRANCH_TYPE"
              :filters="filters"
              :filter-options="filterOptions"
              @change-filter="changeFilter"
            />
          </div>
          <div class="col-span-4">
            <RangeFilter
              label="office.treatmentRoomsFilterLabel"
              :filter-key-max="FilterType.TREATMENT_ROOMS_TILL"
              :filter-key-min="FilterType.TREATMENT_ROOMS_FROM"
              :filters="filters"
              :filter-options="filterOptions"
              @change-filter="changeFilter"
            />
          </div>
        </div>
      </FilterPanel>
    </template>
  </List>
</template>

<script setup lang="ts">
import { ETileGridType } from '@/@types/tile-grid-type';
import { scrollToElement } from '@/helpers/scroll';
import LoaderTileRow from '@/components/loader/tile-row.vue';
import NoEntries from '../../components/noEntries/vdv/no-entries.vue';
import TileView from '@/components/components/views/tileView/tile-view.vue';
import { SSR_safe_mq_breakpointIsMobile } from '@/injectionSymbols';
import type { OfficeListData } from '../blockType';
import List from '@/components/components/advancedList/list.vue';
import RangeFilter from '@/components/components/advancedList/components/filters/rangeFilter.vue';
import { FilterType } from '../../components/filter/utils';
import DropdownFilter from '@/components/components/advancedList/components/filters/dropdownFilter/dropdownFilter.vue';
import FilterPanel from '@/components/components/advancedList/components/filterPanel/filterPanel.vue';

const props = defineProps({
  blockData: {
    type: Object as PropType<OfficeListData>,
    required: true,
    default: {} as OfficeListData,
  },
});
const filterPanelOpen = ref(false);

const isMobile = inject(SSR_safe_mq_breakpointIsMobile);
const router = useRouter();

// scroll to list if filter is set in url query
onMounted(async () => {
  if (
    router.currentRoute?.value.query &&
    Object.keys(router.currentRoute.value.query).length
  ) {
    const element = document.getElementById(props.blockData.id);
    scrollToElement(element, isMobile ? 0 : 120);
  }
});

function getQueryArgsForTiles(filter: Record<string, any>) {
  return Object.entries(filter).reduce(
    (acc, [k, v]) => {
      acc[k] = encodeURI(JSON.stringify(v));
      return acc;
    },
    {} as Record<string, string>,
  );
}
</script>
