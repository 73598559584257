<template>
  <div>
    <div v-if="label" class="text-sm font-bold pb-2xs">
      {{ t(label) }}
    </div>

    <div class="flex items-center w-full h-input-h-md shrink-0">
      <RangeSlider v-model="value" :min="min" :max="max" />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { FilterOptions } from '../../useAdvancedList';
import RangeSlider from '@/components/range-slider.vue';

const props = defineProps({
  filterKeyMin: {
    type: String,
    required: true,
  },
  filterKeyMax: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  filters: {
    type: Object as PropType<Record<string, any>>,
    required: true,
    default: () => ({}) as Record<string, any>,
  },
  filterOptions: {
    type: Object as PropType<FilterOptions>,
    required: true,
    default: () => ({}) as FilterOptions,
  },
});

const emits = defineEmits<{
  (e: 'changeFilter', key: string, value: any): void;
}>();

const { t } = useTrans();

const min = computed<number>(() =>
  reduceMinMaxFilterOptions(props.filterKeyMin, Math.min, 99999),
);
const max = computed<number>(() =>
  reduceMinMaxFilterOptions(props.filterKeyMax, Math.max, 0),
);

const value = ref([min.value, max.value]);

watch(value, (v, oldV) => {
  // If rooms min has min value, and max has max value -> delete the filters to also include Entries with no rooms

  // Swap till/from for proper range-inclusion
  // e.g. (from:2, till:3) should include 3-6 or 1-4, but not 4-6 or 1

  if (v[0] !== oldV[0])
    emits('changeFilter', props.filterKeyMax, v[0] === min.value ? null : v[0]);
  if (v[1] !== oldV[1])
    emits('changeFilter', props.filterKeyMin, v[1] === max.value ? null : v[1]);
});

watch(
  () => props.filters[props.filterKeyMin],
  (nv) => {
    // Since we delete filters on reset, we need to check if nv is undefined
    if (nv === undefined) {
      value.value[1] = max.value;
      return;
    }
    if (value.value[1] !== nv) {
      value.value[1] = nv;
    }
  },
);

watch(
  () => props.filters[props.filterKeyMax],
  (nv) => {
    // Since we delete filters on reset, we need to check if nv is undefined
    if (nv === undefined) {
      value.value[0] = min.value;
      return;
    }
    if (value.value[0] !== nv) {
      value.value[0] = nv;
    }
  },
);

function reduceMinMaxFilterOptions(
  key: string,
  cb: (...values: number[]) => number,
  start: number,
) {
  if (props.filterOptions && props.filterOptions[key]?.values.length) {
    return props.filterOptions[key]?.values.reduce(
      (acc, { label }) => cb(acc, parseInt(label, 10)),
      start,
    );
  }
  return null;
}
</script>
